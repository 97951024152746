<template>
	<div>
		<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
			{{ FormMSG(2, 'Locations list') }}
		</h1>
		<div class="container-mobile animated fadeIn" style="top: 95px">
			<div class="form content-contact-mobile">
				<b-row>
					<b-col>
						<b-form-group v-slot="{ ariaDescribedby }">
							<b-form-radio-group
								id="showOptionLocation"
								v-model="showOptionSelected"
								:options="showOptions"
								:aria-describedby="ariaDescribedby"
								name="radio-options"
								plain
								@change="handleChangeShowOptionSelected"
							/>
						</b-form-group>
					</b-col>
				</b-row>

				<b-row>
					<b-col style="display: flex">
						<div class="form-input-search">
							<b-form-input v-model="filter" type="search" :placeholder="FormMSG(9, 'Type to Search')" />
						</div>
						<div class="form-input-icon-search" style="cursor: pointer">
							<component :is="getLucideIcon('Search')" v-if="filter === ''" color="#06263E" :size="16" />
							<component :is="getLucideIcon('X')" v-else color="#06263E" :size="16" @click="filter = ''" />
						</div>
					</b-col>
				</b-row>

				<b-row class="mt-3 mb-3">
					<b-col>
						<div class="title-list">
							<div class="fs-16 fw-400">{{ FormMSG(2, 'Locations list') }}</div>
						</div>
					</b-col>
				</b-row>

				<div v-if="locationsFilteredMap.length === 0" class="text-center">
					{{ FormMSG(587, 'No data found') }}
				</div>
				<div>
					<div v-for="(location, index) in locationsFilteredMap" :key="index">
						<div v-if="index > 0" class="divider-line mt-3 mb-3" />
						<b-row>
							<b-col cols="10">
								<p class="fs-16 fw-700 mb-1">{{ location.setName }}</p>
								<div class="wrap-status d-flex justify-content-start">
									<div class="status info fs-11 fw-700">
										<div class="txt-capitalize">
											{{ location.getInDate | formatDate('DD MMMM YYYY') }} -
											{{ location.getOutDate | formatDate('DD MMMM YYYY') }}
										</div>
									</div>
								</div>
							</b-col>
							<b-col cols="2">
								<div class="d-flex align-items-center justify-content-center h-100-percent">
									<b-dropdown no-caret variant="none" dropleft>
										<template #button-content>
											<div>
												<component :is="getLucideIcon('MoreVertical')" :size="20" />
											</div>
										</template>
										<b-dropdown-item @click="handleClickEdit(location)">
											<button class="btn-transparent text-color-rhapsody-in-blue">
												<component :is="getLucideIcon('Edit')" :size="16" />
												{{ FormMSG(47, 'Edit') }}
											</button>
										</b-dropdown-item>
										<b-dropdown-item v-if="location.images.length > 0" @click="handleClickShowImage(location)">
											<button class="btn-transparent text-color-rhapsody-in-blue">
												<component :is="getLucideIcon('File')" :size="16" />
												{{ FormMSG(45, 'Show image') }}
											</button>
										</b-dropdown-item>
										<b-dropdown-item @click="handleClickDelete(location)">
											<button class="btn-transparent text-color-burning-tomato">
												<component :is="getLucideIcon('Trash2')" :size="16" />
												{{ FormMSG(46, 'Delete') }}
											</button>
										</b-dropdown-item>
									</b-dropdown>
								</div>
							</b-col>
						</b-row>
					</div>
					<b-row class="footer-fixed">
						<b-col cols="12">
							<b-button variant="primary" block style="font-size: 16px; font-weight: 700" @click="handleClickAddLocation">
								{{ FormMSG(85, 'Add location') }}
							</b-button>
						</b-col>
					</b-row>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import globalMixin from '@/mixins/global.mixin';
import languageMessages from '@/mixins/languageMessages';
import LocationItem from './Item';
import moment from 'moment';
import _ from 'lodash';

export default {
	name: 'MobileViewsLocationMainComponent',
	mixins: [languageMessages, globalMixin],
	components: { LocationItem },
	props: {
		locations: {
			type: Array,
			required: true,
			default: () => []
		},
		showDeleteButton: {
			type: Boolean,
			required: false,
			default: true
		}
	},
	data() {
		return {
			filter: '',
			showOptionSelected: -1
		};
	},
	created() {
		moment.locale(this.lang);
	},
	computed: {
		showOptions() {
			return [
				{ text: this.FormMSG(148, 'All'), value: -1 },
				{ text: this.FormMSG(175, 'Confirmed'), value: 0 },
				{ text: this.FormMSG(169, 'Potential'), value: 1 }
			];
		},
		locationsFilteredMap() {
			let locations = _.cloneDeep(this.locations);
			var filter = this.filter.trim().toLowerCase();
			if (filter === '') return locations;
			var ar = locations.filter(function (s) {
				return s.setName.toLowerCase().indexOf(filter) > -1;
			});
			//console.log("ar:",ar);
			return ar;
		}
	},
	methods: {
		handleChangeShowOptionSelected(payload) {
			this.$emit('main-location:change-option', payload);
		},
		handleClickAddLocation() {
			this.$emit('main-location:add');
		},
		handleClickEdit(location) {
			this.$emit('main-location:edit', location);
		},
		handleClickDelete(location) {
			this.$emit('main-location:delete', location);
		},
		handleClickShowImage(location) {
			this.$emit('main-location:show-image', location.images);
		}
	}
};
</script>
